$primary-color: #1FA9FF;
$primary-light-color: #A2DBFF;
$primary-lighter-color: #E4F4FF;
$primary-dark-color: #042439;
$secondary-color: #f50057;
$orange-color: #FF4A2B;
$yellow-color: #F5EE9E;
$yellow-dark-color: #FFCC01;
$secondary-light-color: #395263;
$white-color: #fff;
$black-color: #000;
$green-color:#6DBA20;
$link-color: #1F39FF;
$placeholder-color: #EDF2F6;
$egg-blue-color: #00E0BE;
$default-font-family: "Roboto", sans-serif;
$default-transition-duration: 0.3s;
